import { Ref, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  LanguageCode,
  widget,
} from '../../charting_library'
import DATAFEED from './datafeed'
import moment from 'moment'
import {
  addCustomFullScreenButton,
  checkExternalFeatureParams,
  defaultChartWidgetOptions,
} from '../function'

const ExchangeChartContainer = () => {
  const socketIo = useRef<any>(null)

  const ref: Ref<any> = useRef()
  const widgetRef = useRef<IChartingLibraryWidget>()
  const [searchParams, _] = useSearchParams()
  const symbol = searchParams.get('symbol')?.toUpperCase() as string
  const periodRef = useRef({
    to: moment().unix(),
    from: moment().subtract(6, 'months').unix(),
  })

  useEffect(() => {
    if (!ref.current) return

    const widgetOptions: ChartingLibraryWidgetOptions = {
      ...defaultChartWidgetOptions,
      symbol: symbol,
      datafeed: DATAFEED({
        widgetRef,
        periodRef,
        socketIo,
      }),
      container: ref.current,
      locale: (searchParams.get('language') as LanguageCode) || 'vi',
      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
        'symbol_search_hot_key',
      ],
      enabled_features: [
        'study_templates',
        'pinch_scale',
        'show_zoom_and_move_buttons_on_touch',
        'vert_touch_drag_scroll',
        'horz_touch_drag_scroll',
      ],
      studies_overrides: {
        'moving average.ma20.color': '#ff0000',
        'moving average.ma50.color': '#00ff00',
        'relative strength index.rsi.color': '#0000ff',
      },
      timeframe: '3m',
      time_frames: [
        {
          text: '2y',
          resolution: '1W' as ChartingLibraryWidgetOptions['interval'],
          description: '2 Years',
          title: '2Y',
        },
        {
          text: '1y',
          resolution: '1W' as ChartingLibraryWidgetOptions['interval'],
          description: '1 Years',
          title: '1Y',
        },
        {
          text: '6m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '6 Months',
          title: '6M',
        },
        {
          text: '3m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '3 Months',
          title: '3M',
        },
        {
          text: '1m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '1 Months',
          title: '1M',
        },
      ],
    }

    const tvWidget = new widget(
      checkExternalFeatureParams(searchParams, widgetOptions),
    )

    tvWidget.headerReady().then(() => {
      widgetRef.current = tvWidget

      if (searchParams.get('custom-fullscreen'))
        addCustomFullScreenButton(tvWidget)

      tvWidget.chart().createStudy('Relative Strength Index', false, false, {})

      tvWidget.chart().createStudy(
        'Moving Average',
        false,
        false,
        { length: 20 },
        {
          'Plot.color': '#ff0000',
        },
      )

      tvWidget.chart().createStudy(
        'Moving Average',
        false,
        false,
        { length: 50 },
        {
          'Plot.color': '#00ff00',
        },
      )
    })

    return () => {
      if (tvWidget) tvWidget.remove()
      socketIo.current?.close()
    }
  }, [searchParams])

  return (
    <div ref={ref} className={'TVChartContainer'} style={{ height: '100%' }} />
  )
}

export default ExchangeChartContainer
