import axios from 'axios'
import _orderBy from 'lodash/orderBy'
import moment from 'moment'
import {
  Bar,
  LibrarySymbolInfo,
  ResolutionString,
  SubscribeBarsCallback,
} from '../../charting_library'

const getResolution = (resolution: string) => {
  switch (resolution) {
    case '1':
      return '1min'
    case '5':
      return '5min'
    case '15':
      return '15min'
    case '30':
      return '30min'
    case '60':
      return '1h'
    default:
      return 'day'
  }
}

export default ({ indicator, socketIo }: any) => ({
  onReady: (callback: any) => {
    callback({})
  },
  searchSymbols: () => {},
  resolveSymbol: async (
    symbolName: any,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: any,
  ) => {
    try {
      const data = {
        name: symbolName || '',
        minmov: 1,
        minmov2: 0,
        pointvalue: 1,
        session: '24x7',
        has_intraday: true,
        has_no_volume: false,
        supported_resolutions: ['1', '5', '15', '30', '60', 'D'],
        intraday_multipliers: ['1', '5', '15', '30', '60', 'D'],
        pricescale: 100,
        ticker: symbolName,
        volume_precision: 2,
      }

      onSymbolResolvedCallback(data)
    } catch (error) {
      onResolveErrorCallback(error)
    }
  },
  getBars: async (
    symbolInfo: any,
    resolution: any,
    periodParams: any,
    onHistoryCallback: any,
    onErrorCallback: any,
  ) => {
    const { from, to, firstDataRequest } = periodParams
    let calculateFrom = from
    let calculateTo = to

    if (firstDataRequest) {
      calculateFrom = moment().subtract(24, 'months').unix()
      calculateTo = moment().unix()
    }

    try {
      const {
        data: { data },
      } = await axios.get(
        `${
          process.env.REACT_APP_DATA_PLATFORM_API_URL
        }/trading-view/indicators/${indicator}?symbol=${
          symbolInfo.ticker
        }&resolution=${getResolution(resolution)}&startDate=${moment
          .unix(calculateFrom)
          .format('YYYY-MM-DDTHH:mm:ss')}&endDate=${moment
          .unix(calculateTo)
          .format('YYYY-MM-DDTHH:mm:ss')}`,
      )

      const sortedData = _orderBy(data, ['date', 'asc']).sort()

      const convertedData = sortedData.map((item: any, index: number) => ({
        low: item.value,
        high: item.value,
        open: item.value,
        close: item.value,
        volume: item.value,
        time: moment(item.date).unix() * 1000,
        opening_time: moment(item.date).unix() * 1000,
        closing_time: sortedData[index + 1]
          ? moment(sortedData[index + 1].date).unix() * 1000
          : moment(item.date).unix() * 1000,
      }))

      onHistoryCallback(convertedData, { noData: convertedData.length === 0 })
    } catch (error) {
      onErrorCallback(error)
    }
  },
  subscribeBars: async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
  ) => {
    if (!process.env.REACT_APP_SOCKET_URL_V2) return
    socketIo.current?.close()

    socketIo.current = new WebSocket(
      `${process.env.REACT_APP_SOCKET_URL_V2}/ws`,
    )

    socketIo.current.onopen = () => {
      socketIo.current?.send(
        JSON.stringify({
          operator: 'UNSUB',
          endpoint: 'stock_rank',
          params: { stocks: [symbolInfo.ticker] },
        }),
      )
      socketIo.current?.send(
        JSON.stringify({
          operator: 'SUB',
          endpoint: 'stock_rank',
          params: { stocks: [symbolInfo.ticker] },
        }),
      )
    }

    socketIo.current.onmessage = ({ data }: any) => {
      try {
        const rankResponse = JSON.parse(data)
        const firstRank = rankResponse?.stocksRating?.data?.find(
          (a: any) => a.code === symbolInfo.ticker,
        )

        if (firstRank) {
          const value =
            indicator === 'ma' ? firstRank?.datxMa : firstRank?.volRate
          const tickData = {
            time: moment().unix() * 1000,
            close: value,
            open: value,
            high: value,
            low: value,
            volume: value,
          }

          onTick(tickData)
        }
      } catch (error) {}
    }
  },
  unsubscribeBars: () => {},
})
