import { gql } from '@apollo/client'

export const SUB_SECTORS_RATING = gql`
  subscription sectorRatingV2Sub {
    sectorRatingV2 {
      sector
      gradeRank
      prevRank
      rank
      rating
      sectorVi
      totalRank
    }
  }
`

export const SUB_STOCKS_RATING = gql`
  subscription stockRatingV2Sub {
    stockRatingV2 {
      sector
      code
      rank
      totalPoints
    }
  }
`
