import { gql } from '@apollo/client'

export const GET_SIGNAL_HISTORY = gql`
  query StockSignalHistory($input: StockSignalHistoryInput!) {
    stockSignalHistory(input: $input) {
      data {
        id
        date
        ticker
        recAction
        openDate
        closedDate
        openPrice
        closedPrice
        tpPrice
        slPrice
        recType
        maxAllocation
        profitPercent
        status
        recTypeLabel
        latestPrice
      }
      meta {
        total
        page
        pages
        pageSize
      }
    }
  }
`

export const GET_DERIVATIVES_RECOMMENDATION = gql`
  query DerivativesRecommendation($input: DerivativesRecommendationInput!) {
    derivativesRecommendation(input: $input) {
      id
      date
      ticker
      type
      role
      price
      quantity
      percentage
      holdingPercentage
    }
  }
`
