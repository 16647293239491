export const sectorTranslationVi = {
  agricalture: 'Thực phẩm, Đồ uống',
  banks: 'Ngân hàng',
  chemicals: 'Hóa chất',
  constructions: 'Xây dựng',
  consumers: 'Tiêu dùng & bán lẻ',
  energy: 'Năng lượng',
  financials: 'Tài chính',
  fisheriesIndustry: 'Thủy sản',
  healthcare: 'Y tế',
  industrials: 'Công nghiệp',
  industryRealEstate: 'BĐS Khu Công nghiệp',
  informationTechnology: 'Công nghệ thông tin',
  insurances: 'Bảo hiểm',
  materials: 'Nguyên vật liệu',
  publicInvestmentSector: 'Đầu tư công',
  realEstate: 'Bất động sản',
  shippingIndustry: 'Vận tải biển',
  stateBanks: 'Ngân hàng quốc doanh',
  steelIndustry: 'Thép',
  textileIndustry: 'Dệt may',
  utility: 'Dịch vụ tiện ích',
} as any

export const sectorTranslationEn = {
  agricalture: 'Agricalture',
  banks: 'Banks',
  chemicals: 'Chemicals',
  constructions: 'Constructions',
  consumers: 'Consumers',
  energy: 'Energy',
  financials: 'Financials',
  fisheriesIndustry: 'Fisheries Industry',
  healthcare: 'Healthcare',
  industrials: 'Industrials',
  industryRealEstate: 'Industry Real Estate',
  informationTechnology: 'Information Technology',
  insurances: 'Insurances',
  materials: 'Materials',
  publicInvestmentSector: 'Public Investment Sector',
  realEstate: 'Real Estate',
  shippingIndustry: 'Shipping Industry',
  stateBanks: 'State Banks',
  steelIndustry: 'Steel Industry',
  textileIndustry: 'Textile Industry',
  utility: 'Utility',
} as any

export const RANK_COLORS = [
  '#01A612',
  '#F2ED49',
  '#FF00B8',
  '#FF0100',
  '#30C3F3',
  '#9105FF',
  '#F67600',
  '#83FFAB',
  '#0029FF',
  '#938cec',
]
