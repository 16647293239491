import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import Cookies from 'js-cookie'

let activeSocket: any, timedOut: any

const wsLink: any = () => {
  if (typeof window === 'undefined') return null

  const urlParams = new URLSearchParams(window.location.search)
  const userToken = urlParams.get('accessToken')
  const accessToken = userToken || Cookies.get('access_token')

  return new GraphQLWsLink(
    createClient({
      url: String(process.env.REACT_APP_SOCKET_GRAPHQL_URL),
      keepAlive: 5000,
      retryAttempts: Infinity,
      connectionParams: {
        authToken: `Bearer ${accessToken}`,
      },
      shouldRetry: () => true,
      on: {
        closed: () => {
          // console.log('onClose', socket)
        },
        ping: (received) => {
          if (!received)
            timedOut = setTimeout(() => {
              if (activeSocket?.readyState === WebSocket?.OPEN)
                activeSocket?.close(4408, 'Request Timeout')
            }, 3000)
        },
        pong: (received) => {
          if (received) clearTimeout(timedOut)
        },
      },
    }),
  )
}

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const userToken = urlParams.get('accessToken')
  const accessToken = userToken || Cookies.get('access_token')

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  }
})

const link =
  typeof window !== 'undefined' && wsLink() != null
    ? split(
        ({ query }) => {
          const def = getMainDefinition(query)
          return (
            def.kind === 'OperationDefinition' &&
            def.operation === 'subscription'
          )
        },
        wsLink(),
        authLink.concat(httpLink),
      )
    : httpLink

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
})
