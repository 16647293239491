import { Ref, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import _uniqBy from 'lodash/uniqBy'

import {
  ChartingLibraryWidgetOptions,
  CustomIndicator,
  LanguageCode,
  PineJS,
  RawStudyMetaInfoId,
  widget,
} from '../../../charting_library'
import DATAFEED, { getBars } from './datafeed'
import {
  RANK_COLORS,
  sectorTranslationEn,
  sectorTranslationVi,
} from '../constant'
import {
  addCustomFullScreenButton,
  calculateIntervalTime,
  checkExternalFeatureParams,
  defaultChartWidgetOptions,
} from '../../function'
import { useLazyQuery, useSubscription } from '@apollo/client'
import {
  GET_SECTOR_INFO,
  GET_SECTOR_RANK,
} from '../../../graphQL/queries/rating'
import moment from 'moment'
import _isEqual from 'lodash/isEqual'
import _difference from 'lodash/difference'
import _orderBy from 'lodash/orderBy'
import _last from 'lodash/last'
import { SUB_SECTORS_RATING } from '../../../graphQL/subscription/rating'

const RatingChartContainer = () => {
  const widgetRef = useRef<any>()
  const ref: Ref<any> = useRef()
  const storeData = useRef<any[]>([])
  const [sectorInfo, setSectorInfo] = useState([])
  const dataFeedFunc = useRef<any>({})

  const hasSendMessage = useRef<any>(false)
  const [searchParams, _] = useSearchParams()
  const symbols = useRef(searchParams.get('symbols')?.split(',') || [])
  const locale = (searchParams.get('language') as LanguageCode) || 'vi'
  const [skipSubscription, setSkipSubscription] = useState(true)
  const type = searchParams.get('type') || 'POINT'
  const hasFilter = searchParams.get('hasFilter') || ''
  const canAccess = searchParams.get('canAccess') || ''
  const theme = searchParams.get('theme') || ''
  const periodRef = useRef({
    to: moment().unix(),
    from: moment().subtract(6, 'months').unix(),
  })

  const [fetchData] = useLazyQuery(GET_SECTOR_RANK)

  const [getData] = useLazyQuery(GET_SECTOR_INFO, {
    onCompleted: (data) => {
      setSectorInfo(data?.sectorList)
    },
  })

  const onResetData = async () => {
    const { from, to } = calculateIntervalTime(true, 0, 0, periodRef, 'D')

    try {
      await getBars(fetchData, symbols.current, from, to, storeData, type, null)
    } catch (error) {}
  }

  useSubscription(SUB_SECTORS_RATING, {
    skip: skipSubscription || sectorInfo.length === 0,
    onData: async ({
      data: {
        data: { sectorRatingV2: realtimeData },
      },
    }) => {
      let sectorRatingV2 = _orderBy(
        realtimeData,
        [type === 'POINT' ? 'rating' : 'rank'],
        [type === 'POINT' ? 'desc' : 'asc'],
      )

      if (storeData.current.length > 0 && dataFeedFunc.current?.onTick) {
        if (!hasSendMessage.current)
          window.parent.postMessage(
            JSON.stringify({ site: 'DATX', message: 'SECTOR_SOCKET' }),
            '*',
          )
        let hasChangeRange = false

        if (hasFilter !== '' && canAccess !== '') {
          const newData =
            canAccess === 'false' || hasFilter === 'false'
              ? sectorRatingV2
                  .map((a: any) => a.sector)
                  .slice(0, canAccess === 'false' ? 3 : 5)
              : sectorRatingV2
                  .filter((a: any) => symbols.current.includes(a.sector))
                  .map((a: any) => a.sector)

          if (!_isEqual(newData, symbols.current)) {
            try {
              if (newData[0] === symbols.current[0]) {
                if (_difference(newData, symbols.current).length !== 0) {
                  symbols.current = newData
                  storeData.current = []
                  await onResetData()
                  dataFeedFunc.current?.onResetCacheNeededCallback()
                  widgetRef.current?.chart()?.resetData()
                }
                symbols.current = newData
                hasChangeRange = true
              } else {
                symbols.current = newData
                storeData.current = []
                widgetRef.current
                  .chart()
                  .setSymbol(
                    locale === 'vi'
                      ? sectorTranslationVi[symbols.current[0]]
                      : sectorTranslationEn[symbols.current[0]],
                  )

                return
              }
            } catch (error) {
              console.log('Sector realtime error', error)
            }
          }
        }

        const firstRank = sectorRatingV2.find(
          (a: any) => a.sector === symbols.current[0],
        )

        if (firstRank) {
          const value = type === 'POINT' ? firstRank.rating : firstRank.rank

          const lastItem = _last(_orderBy(storeData.current, 'time', 'asc'))
          const newSocketData: any = {
            time: lastItem
              ? lastItem?.time
              : moment().startOf('day').unix() * 1000,
            close: value,
            open: value,
            high: value,
            low: value,
            volume: value,
          }

          sectorRatingV2.map((a: any) => {
            if (
              a.sector !== symbols.current[0] &&
              symbols.current.includes(a.sector)
            ) {
              newSocketData[a.sector] = type === 'POINT' ? a.rating : a.rank
            }
          })

          // hasChange data
          onRealtimeData(newSocketData)
          if (hasChangeRange) createStudy(widgetRef.current)
        }

        if (!hasSendMessage.current) hasSendMessage.current = true
      }
    },
  })

  const onRealtimeData = (data: any) => {
    const oldData = storeData.current.filter((a) => a.time !== data.time)
    storeData.current = [...oldData, data]
    dataFeedFunc.current?.onTick({
      ...data,
      time: moment().unix() * 1000,
    })
  }

  const customIndicatorsGetter = (PineJS: PineJS) => {
    const items = sectorInfo.map(({ sector, sectorVi }) => {
      const sectorName =
        locale === 'vi' ? sectorVi : sectorTranslationEn[sector]

      return {
        name: `SectorRank${sector}`,
        metainfo: {
          _metainfoVersion: 51,
          id: `sector-${sector}@tv-basicstudies-1` as RawStudyMetaInfoId,
          name: sector,
          description: sector,
          shortDescription: sectorName,
          is_hidden_study: false,
          is_price_study: true,
          isCustomIndicator: true,
          format: {
            type: 'price',
            precision: 0,
          },
          plots: [{ id: 'plot_0', type: 'line' as any }],
          defaults: {
            styles: {
              plot_0: {
                linestyle: 0,
                visible: true,
                linewidth: 2,
                plottype: 2 as any,
                trackPrice: false,
                color: 'red',
              },
            },
            inputs: {},
          },
          styles: {
            plot_0: {
              title: sectorName,
              histogramBase: 0,
            },
          },
          inputs: [],
        },
        constructor: function () {
          const mainThis = this as any
          mainThis.init = function (context: unknown, inputCallback: never) {
            this._context = context
            this._input = inputCallback
          }
          mainThis.main = function (context: unknown, inputCallback: never) {
            this._context = context
            this._input = inputCallback

            const time = PineJS.Std.time(this._context)
            const value = storeData.current.find(
              (a: any) => String(a.time) === String(time),
            ) as any

            return value && value[sector] ? [Number(value[sector])] : []
          }
        },
      } as CustomIndicator
    })

    return Promise.resolve<CustomIndicator[]>(items)
  }

  const createStudy = (tvWidget: any) => {
    tvWidget.chart().removeAllStudies()

    symbols.current.slice(1, 10).map((sector, index) => {
      try {
        tvWidget.chart().createStudy(
          sector,
          false,
          false,
          {},
          {
            styles: {
              plot_0: {
                linestyle: 0,
                visible: true,
                linewidth: 2,
                plottype: 2 as any,
                trackPrice: false,
                color: RANK_COLORS[index + 1],
              },
            },
          },
        )
      } catch (error) {
        console.log('Sector study not loaded', sector)
      }
    })
  }

  useEffect(() => {
    if (!ref.current) return
    if (sectorInfo.length <= 0) return

    const widgetOptions: ChartingLibraryWidgetOptions = {
      ...defaultChartWidgetOptions,
      theme: theme === 'light' ? 'Light' : 'Dark',
      symbol:
        locale === 'vi'
          ? sectorTranslationVi[symbols.current[0]]
          : sectorTranslationEn[symbols.current[0]],
      datafeed: DATAFEED({
        dataFeedFunc,
        storeData,
        fetchData,
        language: locale,
        symbols,
        type,
        widgetRef,
        periodRef,
        setSkipSubscription,
      }),
      container: ref.current,
      locale,
      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
        'symbol_search_hot_key',
        'header_chart_type',
        'header_resolutions',
      ],
      enabled_features: [
        'study_templates',
        'pinch_scale',
        'show_zoom_and_move_buttons_on_touch',
        'vert_touch_drag_scroll',
        'horz_touch_drag_scroll',
        'compare_symbol_search_spread_operators',
      ],
      timeframe: '3m',
      compare_symbols: symbols.current.slice(1, 10).map((symbol) => ({
        symbol: symbol,
        title:
          locale === 'vi'
            ? sectorTranslationVi[symbol]
            : sectorTranslationEn[symbol],
      })),
      custom_indicators_getter: customIndicatorsGetter,
      time_frames: [
        {
          text: '2y',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '2 Years',
          title: '2Y',
        },
        {
          text: '1y',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '1 Years',
          title: '1Y',
        },
        {
          text: '6m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '6 Months',
          title: '6M',
        },
        {
          text: '3m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '3 Months',
          title: '3M',
        },
        {
          text: '1m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '1 Months',
          title: '1M',
        },
      ],
    }

    const tvWidget = new widget(
      checkExternalFeatureParams(searchParams, widgetOptions),
    )

    tvWidget.headerReady().then(() => {
      widgetRef.current = tvWidget
      if (searchParams.get('custom-fullscreen'))
        addCustomFullScreenButton(tvWidget, 'FULL_SCREEN_MODE_SECTOR')
    })

    tvWidget.onChartReady(() => {
      tvWidget.chart().setChartType(2)
      tvWidget.chart().executeActionById('lineHide')
      tvWidget.chart().getSeries().setChartStyleProperties(2, {
        color: RANK_COLORS[0],
      })

      createStudy(tvWidget)

      tvWidget
        .chart()
        .onSymbolChanged()
        .subscribe(null, () => {
          createStudy(tvWidget)
        })
    })

    widgetRef.current = tvWidget

    return () => {
      if (tvWidget) tvWidget.remove()
    }
  }, [searchParams, sectorInfo])

  useEffect(() => {
    getData()
  }, [])

  return (
    <div ref={ref} className={'TVChartContainer'} style={{ height: '100%' }} />
  )
}

export default RatingChartContainer
