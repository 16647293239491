import { gql } from '@apollo/client'

export const SUB_TICKER_SIGNAL = gql`
  subscription SignalLatest($signalTerm: SignalTerm!) {
    signalLatest(signalTerm: $signalTerm) {
      id
      date
      ticker
      recAction
      openDate
      closedDate
      openPrice
      closedPrice
      tpPrice
      slPrice
      recType
      maxAllocation
      profitPercent
      status
      recTypeLabel
      latestPrice
    }
  }
`

export const SUB_DERIVATIVE_RECOMMENDATION = gql`
  subscription DerivativesRecommendationLatest {
    derivativesRecommendationLatest {
      id
      date
      updatedDate
      ticker
      type
      role
      price
      quantity
      percentage
      holding
      holdingPercentage
    }
  }
`
