import { gql } from '@apollo/client'

export const GET_SECTOR_RANK = gql`
  query SectorRatingHistory(
    $codes: [String!]!
    $startDate: Date!
    $endDate: Date!
  ) {
    sectorRatingHistory(
      codes: $codes
      startDate: $startDate
      endDate: $endDate
    ) {
      code
      data {
        date
        totalPoints
        rank
      }
    }
  }
`

export const GET_STOCK_RATING = gql`
  query SectorRatingHistory(
    $codes: [String!]!
    $startDate: Date!
    $endDate: Date!
  ) {
    stockRatingHistory(
      codes: $codes
      startDate: $startDate
      endDate: $endDate
    ) {
      code
      data {
        date
        totalPoints
        rank
      }
    }
  }
`

export const GET_STOCK_INFO = gql`
  query StocksCompany {
    datxStocksCompanyByTicker {
      ticker
    }
  }
`

export const GET_SECTOR_INFO = gql`
  query SectorList {
    sectorList {
      sector
      sectorVi
    }
  }
`
