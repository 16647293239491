import axios from 'axios'
import _orderBy from 'lodash/orderBy'

const sectors = [
  {
    sector: 'banks',
    en: 'Banks',
    vi: 'Ngân hàng',
  },
  {
    sector: 'agricalture',
    en: 'Agricalture',
    vi: 'Thực phẩm, đồ uống',
  },
  {
    sector: 'industrials',
    en: 'Industrials',
    vi: 'Công nghiệp',
  },
  {
    sector: 'utility',
    en: 'Utilities',
    vi: 'Dịch vụ tiện ích',
  },
  {
    sector: 'materials',
    en: 'Materials',
    vi: 'Nguyên vật liệu',
  },
  {
    sector: 'consumers',
    en: 'Consumers',
    vi: 'Tiêu dùng',
  },
  {
    sector: 'informationTechnology',
    en: 'Information Technology',
    vi: 'Công nghệ thông tin',
  },
  {
    sector: 'financials',
    en: 'Financials',
    vi: 'Tài chính',
  },
  {
    sector: 'fisheriesIndustry',
    en: 'Fisheries Industry',
    vi: 'Thuỷ sản',
  },
  {
    sector: 'constructions',
    en: 'Constructions',
    vi: 'Xây dựng',
  },
  {
    sector: 'insurances',
    en: 'Insurance',
    vi: 'Bảo hiểm',
  },
  {
    sector: 'realEstate',
    en: 'Real Estate',
    vi: 'Bất động sản',
  },
  {
    sector: 'energy',
    en: 'Energy',
    vi: 'Năng lượng',
  },
  {
    sector: 'healthcare',
    en: 'Health Care',
    vi: 'Y tế',
  },
  {
    sector: 'shippingIndustry',
    en: 'Shipping Industry',
    vi: 'Vận tải biển',
  },
  {
    sector: 'industryRealEstate',
    en: 'Industry Real Estate',
    vi: 'BĐS Công nghiệp',
  },
  {
    sector: 'textileIndustry',
    en: 'Textile Industry',
    vi: 'Dệt may',
  },
  {
    sector: 'chemicals',
    en: 'Chemicals',
    vi: 'Hoá chất',
  },
]

export default ({ storeData, language, widgetRef }: any) => ({
  onReady: (callback: any) => {
    callback({})
  },
  searchSymbols: () => {},
  resolveSymbol: async (
    symbolName: any,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: any,
  ) => {
    try {
      const sector = sectors.find((a) => a.sector === symbolName) || ({} as any)
      const data = {
        name: sector[language] || '',
        minmov: 1,
        minmov2: 0,
        pointvalue: 1,
        session: '24x7',
        has_intraday: true,
        has_no_volume: false,
        supported_resolutions: ['60', '240', '720', '1D', '1W'],
        pricescale: 100,
        ticker: symbolName,
        volume_precision: 2,
      }

      onSymbolResolvedCallback(data)
    } catch (error) {
      onResolveErrorCallback(error)
    }
  },
  getBars: async (
    symbolInfo: any,
    resolution: any,
    periodParams: any,
    onHistoryCallback: any,
    onErrorCallback: any,
  ) => {
    const { from, to } = periodParams
    const extraTimezone = 11 * 60 * 60 * 1000
    const timezone = widgetRef.current
      ? widgetRef.current.chart().getTimezoneApi().getTimezone().offset +
        extraTimezone
      : 25200000 + extraTimezone

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_GOLDEN_GROSS_API_URL}/history?sector=${symbolInfo.ticker}&from=${from}&to=${to}`,
      )

      const convertedData = _orderBy(data, ['timestamp', 'asc'])
        .sort()
        .map((item: any, index: number) => ({
          ...item,
          low: item.Close,
          high: item.Close,
          open: item.Close,
          close: item.Close,
          volume: item.Volume,
          time: item.timestamp + timezone,
          opening_time: item.timestamp + timezone,
          closing_time: data[index + 1]
            ? data[index + 1].timestamp + timezone
            : item.timestamp + timezone,
        }))

      storeData.current = [...storeData.current, ...convertedData]

      onHistoryCallback(convertedData, { noData: data.length === 0 })
    } catch (error) {
      onErrorCallback(error)
    }
  },
  subscribeBars: async () => {},
  unsubscribeBars: () => {},
})
