import StockRankChart from '../components/RatingChart/Stock'

const SectorRank = () => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <StockRankChart />
    </div>
  )
}

export default SectorRank
