import ChartContainer from '../components/WatchListChart'

const WatchListChart = () => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <ChartContainer />
    </div>
  )
}

export default WatchListChart
