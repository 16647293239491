import { Ref, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  LanguageCode,
  widget,
} from '../../charting_library'
import DATAFEED from './datafeed'
import moment from 'moment'
import {
  addCustomFullScreenButton,
  checkExternalFeatureParams,
  defaultChartWidgetOptions,
} from '../function'

const ChartContainer = () => {
  const socketIo = useRef<any>(null)
  const ref: Ref<any> = useRef()
  const widgetRef = useRef<IChartingLibraryWidget>()
  const periodRef = useRef({
    to: moment().unix(),
    from: moment().subtract('6', 'months').unix(),
  })
  const [searchParams, _] = useSearchParams()

  useEffect(() => {
    if (!ref.current) return

    const widgetOptions: ChartingLibraryWidgetOptions = {
      ...defaultChartWidgetOptions,

      symbol: searchParams.get('symbol')?.toUpperCase() as string,
      datafeed: DATAFEED({ widgetRef, periodRef, socketIo }),
      container: ref.current,
      locale: (searchParams.get('language') as LanguageCode) || 'vi',
      disabled_features: ['use_localstorage_for_settings'],
      enabled_features: [],
      time_frames: [
        {
          text: '2y',
          resolution: '1W' as ChartingLibraryWidgetOptions['interval'],
          description: '2 Years',
          title: '2Y',
        },
        {
          text: '1y',
          resolution: '1W' as ChartingLibraryWidgetOptions['interval'],
          description: '1 Years',
          title: '1Y',
        },
        {
          text: '6m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '6 Months',
          title: '6M',
        },
        {
          text: '3m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '3 Months',
          title: '3M',
        },
        {
          text: '1m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '1 Months',
          title: '1M',
        },
      ],
    }

    const tvWidget = new widget(
      checkExternalFeatureParams(searchParams, widgetOptions),
    )

    tvWidget.headerReady().then(() => {
      widgetRef.current = tvWidget

      if (searchParams.get('custom-fullscreen'))
        addCustomFullScreenButton(tvWidget)
    })

    return () => {
      if (tvWidget) tvWidget.remove()
      socketIo.current?.close()
    }
  }, [searchParams])

  return (
    <div ref={ref} className={'TVChartContainer'} style={{ height: '100%' }} />
  )
}

export default ChartContainer
