import axios from 'axios'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'
import _orderBy from 'lodash/orderBy'

import moment from 'moment'
import {
  Bar,
  LibrarySymbolInfo,
  ResolutionString,
  SubscribeBarsCallback,
} from '../../charting_library/charting_library'
import {
  calculateIntervalTime,
  convertedInterval,
  currencyFormatter,
  symbolChartRequest,
} from '../function'

export type Recommendation = {
  date: string
  holdingPercentage: number
  id: string
  percentage: number
  price: number
  quantity: number
  role: string
  ticker: string
  type: string
}

const headerStyle = {
  color: '#B0B0B0',
  fontSize: 12,
  fontWeight: 500,
}

const itemStyled = { color: '#FFFFFF', fontSize: 11, fontWeight: 500 }

const columns = [
  {
    renderHeader: (width: number) => (
      <div style={{ ...headerStyle, width }}>Ngày GD</div>
    ),
    render: (item: Recommendation, width: number) => (
      <div style={{ ...itemStyled, width }}>
        {moment(item.date).format('HH:mm:ss DD/MM/YY')}
      </div>
    ),
  },
  {
    renderHeader: (width: number) => (
      <div style={{ ...headerStyle, width }}>Lệnh</div>
    ),
    render: (item: Recommendation, width: number) => (
      <div
        style={{
          ...itemStyled,
          width,
        }}
      >
        <div
          style={{
            width: 60,
            background: item.type === 'sell' ? '#EF1944' : '#1FCD45',
            padding: '1px 3px',
            color: '#fff',
            borderRadius: 4,
            textAlign: 'center',
          }}
        >
          {item.type === 'sell' ? 'Short' : 'Long'} {Math.abs(item.percentage)}%
        </div>
      </div>
    ),
  },
  {
    renderHeader: (width: number) => (
      <div style={{ ...headerStyle, width }}>Vị thế TK</div>
    ),
    render: (item: Recommendation, width: number) => (
      <div
        style={{
          ...itemStyled,
          width,
        }}
      >
        {item.holdingPercentage < 0 ? 'SHORT' : 'LONG'}{' '}
        {Math.abs(item.holdingPercentage)}%
      </div>
    ),
  },
  {
    renderHeader: (width: number) => (
      <div style={{ ...headerStyle, width }}>Loại lệnh</div>
    ),
    render: (item: Recommendation, width: number) => (
      <div style={{ ...itemStyled, width }}>
        {item.role === 'open' && 'Mở'}
        {item.role === 'close' && 'Đóng'}
        {item.role === 'closeAndOpen' && 'Đóng & mở mới'}
      </div>
    ),
  },
  {
    renderHeader: (width: number) => (
      <div style={{ ...headerStyle, width }}>Giá đặt</div>
    ),
    render: (item: Recommendation, width: number) => (
      <div style={{ ...itemStyled, width }}>
        {currencyFormatter(item.price)}
      </div>
    ),
  },
]

const RecommendationTable = ({ data }: { data: Recommendation[] }) => {
  const widthMap = [125, 80, 80, 95, 50]

  return (
    <div
      style={{
        background: '#343957',
        position: 'absolute',
        right: -20,
        top: -20,
        width: '27.5rem',
        padding: 5,
        paddingTop: 0,
        zIndex: 999,
        borderRadius: 4,
      }}
    >
      <div style={{ display: 'flex', padding: '10px 5px', paddingBottom: 5 }}>
        {columns.map((item, index) => item.renderHeader(widthMap[index]))}
      </div>
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        {data.slice(0, 5).map((item) => (
          <div
            style={{ display: 'flex', padding: 5, alignItems: 'center' }}
            key={`${item.id}-${item.type}-${item.date}`}
          >
            {columns.map((column, index) =>
              column.render(item, widthMap[index]),
            )}
          </div>
        ))}
        {data.length > 5 && (
          <div style={{ fontSize: 12, color: '#AAAAAA', padding: 5 }}>
            Xem toàn bộ dữ liệu tại danh sách Tín hiệu
          </div>
        )}
      </div>
    </div>
  )
}

const groupCondition = (resolution: string, time: string) => {
  let calculated = 0
  let range = 0
  switch (resolution) {
    case '1':
      return moment(time).startOf('minutes').format('DD-MM-YYYY-HH:mm')
    case '5':
      range = Number(moment(time).format('mm')) / 5
      calculated = range < 1 ? 0 : Math.round(range)

      return `${moment(time).startOf('hours').format('DD-MM-YYYY-HH')}:${
        calculated * 5 < 10 ? `0${calculated * 5}` : calculated * 5
      }`

    case '15':
      range = Number(moment(time).format('mm')) / 15
      calculated = range < 1 ? 0 : Math.round(range)

      return `${moment(time).startOf('hours').format('DD-MM-YYYY-HH')}:${
        calculated * 15 < 10 ? `0${calculated * 15}` : calculated * 15
      }`

    case '30':
      range = Number(moment(time).format('mm')) / 30
      calculated = range < 1 ? 0 : Math.round(range)

      return `${moment(time).startOf('hours').format('DD-MM-YYYY-HH')}:${
        calculated * 30 < 10 ? `0${calculated * 30}` : calculated * 30
      }`

    case '1W':
      return `${moment(time).format('MM-YYYY')}-${moment(time).week()}`
    case '1M':
      return moment(time).startOf('months').format('MM-YYYY')

    case '60':
      return moment(time) > moment(time).startOf('days').add(15, 'hours')
        ? moment(time).startOf('days').add(14, 'hours').format('DD-MM-YYYY HH')
        : moment(time).format('DD-MM-YYYY HH')
    case '120':
      // has 3 candle
      // case 1 candle from 9h => 11h

      if (moment(time) < moment(time).startOf('days').add(11, 'hours'))
        return moment(time)
          .startOf('days')
          .add(9, 'hours')
          .format('DD-MM-YYYY HH')

      // case 2 candle from 11h => 13h
      if (
        moment(time) >= moment(time).startOf('days').add(11, 'hours') &&
        moment(time) < moment(time).startOf('days').add(13, 'hours')
      )
        return moment(time)
          .startOf('days')
          .add(11, 'hours')
          .format('DD-MM-YYYY HH')

      // case 3 candle from 13h to end candle
      return moment(time)
        .startOf('days')
        .add(13, 'hours')
        .format('DD-MM-YYYY HH')

    case '180':
      // has 2 candle
      // case 1 candle from 9h => 11h
      if (moment(time) < moment(time).startOf('days').add(12, 'hours'))
        return moment(time)
          .startOf('days')
          .add(9, 'hours')
          .format('DD-MM-YYYY HH')

      // case 2 candle from 12h to end candle
      return moment(time)
        .startOf('days')
        .add(12, 'hours')
        .format('DD-MM-YYYY HH')

    case '240':
      // has 2 candle
      // case 1 candle from 9h => 13h
      if (moment(time) < moment(time).startOf('days').add(13, 'hours'))
        return moment(time)
          .startOf('days')
          .add(9, 'hours')
          .format('DD-MM-YYYY HH')

      // case 2 candle from 13h to end candle
      return moment(time)
        .startOf('days')
        .add(13, 'hours')
        .format('DD-MM-YYYY HH')

    default:
      return moment(time).endOf('days').format('DD-MM-YYYY')
  }
}

const getTime = (resolution: string, date: string) => {
  switch (resolution) {
    case '1':
    case '3':
    case '5':
    case '15':
    case '30':
      return moment(date, 'DD-MM-YYYY-HH:mm').startOf('minutes').unix()
    case '60':
    case '120':
    case '180':
    case '240':
      return moment(date, 'DD-MM-YYYY HH').startOf('hours').unix()

    default:
      return moment(date, 'DD-MM-YYYY').endOf('days').unix()
  }
}

const calculatedMark = (data: Recommendation[], resolution: string) => {
  const marks: any = []

  if (data?.length > 0) {
    const items = _groupBy(data, (item) =>
      groupCondition(resolution, item.date),
    )

    _map(items, (recommendation, date) => {
      let color = '#EA1212'

      if (
        recommendation?.length > 1 &&
        recommendation.filter((a) => a.type === 'buy')?.length >= 1 &&
        recommendation.filter((a) => a.type === 'sell')?.length >= 1
      ) {
        color = '#005DFB'
      } else if (recommendation[0].type === 'buy') {
        color = '#00BA3C'
      }

      marks.push({
        id: `recommendation-${date}-${recommendation[0]?.id}`,
        time: getTime(resolution, date),

        color: {
          border: 'white',
          background: color,
          borderWidth: 0,
        },
        borderWidth: 2,
        hoveredBorderWidth: 2,
        text: <RecommendationTable data={recommendation} />,
        label: ' ',
        labelFontColor: 'white',
        minSize: 14,
      })
    })
  }

  return marks
}

const onConnectSocket = (
  socketIo: any,
  ticker: string,
  convertedResolution: string,
  onTick: any,
) => {
  socketIo.current = new WebSocket(
    `${process.env.REACT_APP_SOCKET_URL_V2}/last-tick/${ticker}/${convertedResolution}`,
  )

  socketIo.current.onmessage = ({ data }: any) => {
    try {
      const response = JSON.parse(data)

      for (let i = 0; i < response.t.length; ++i) {
        const barValue: Bar = {
          time: moment().unix() * 1000,
          close: parseFloat(response.c[i]),
          open: parseFloat(response.o[i]),
          high: parseFloat(response.h[i]),
          low: parseFloat(response.l[i]),
          volume: parseFloat(response.v[i]),
        }

        onTick(barValue)
      }
    } catch (error) {}
  }

  socketIo.current.onclose = (e: any) => {
    console.log(
      'Socket is closed. Reconnect will be attempted in 1 second.',
      e?.reason,
    )
    setTimeout(function () {
      onConnectSocket(socketIo, ticker, convertedResolution, onTick)
    }, 1000)
  }

  socketIo.current.onerror = (err: any) => {
    console.error('Socket encountered error: ', err.message, 'Closing socket')
    socketIo.current.close()
  }
}

export default ({
  widgetRef,
  periodRef,
  socketIo,
  getRecommendation,
}: any) => ({
  onReady: async (callback: any) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_DATA_PLATFORM_API_URL}/trading-view/config`,
    )

    callback({ data, supports_marks: true })
  },
  searchSymbols: () => {},
  resolveSymbol: async (
    symbolName: any,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: any,
  ) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_DATA_PLATFORM_API_URL}/trading-view/symbols?symbol=${symbolName}`,
      )

      onSymbolResolvedCallback({
        ...data,
        intraday_multipliers: data.supported_resolutions,
        has_intraday: true,
      })
    } catch (error) {
      onResolveErrorCallback(error)
    }
  },
  getBars: async (
    symbolInfo: any,
    resolution: any,
    periodParams: any,
    onHistoryCallback: any,
    onErrorCallback: any,
  ) => {
    const { from, to } = calculateIntervalTime(
      periodParams.firstDataRequest,
      periodParams.from,
      periodParams.to,
      periodRef,
      widgetRef.current?.symbolInterval()?.interval || '15',
    )

    const { bars, meta } = await symbolChartRequest(
      symbolInfo.ticker,
      from,
      to,
      widgetRef.current?.symbolInterval()?.interval || '15',
      onErrorCallback,
    )

    onHistoryCallback(bars, meta)
  },
  async getMarks(
    symbolInfo: any,
    from: any,
    to: any,
    onDataCallback: any,
    resolution: any,
  ) {
    const {
      data: { derivativesRecommendation },
    } = await getRecommendation({
      variables: {
        input: {
          tickers: [symbolInfo.symbol],
          startDate: moment.unix(from).format('YYYY-MM-DD'),
          endDate:
            to > moment().unix()
              ? moment().format('YYYY-MM-DD')
              : moment.unix(to).format('YYYY-MM-DD'),
          limit: 9999,
        },
      },
    })

    const marks = calculatedMark(
      derivativesRecommendation.filter(
        (a: any) =>
          moment(a.date).unix() >=
            moment(a.date).startOf('days').add(9, 'hours').unix() &&
          moment(a.date).unix() <=
            moment(a.date).startOf('days').add(16, 'hours').unix(),
      ),
      resolution,
    )
    onDataCallback(marks)
  },
  subscribeBars: async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
  ) => {
    const convertedResolution = convertedInterval(
      widgetRef.current?.symbolInterval()?.interval,
    )

    onConnectSocket(
      socketIo,
      symbolInfo.ticker as string,
      convertedResolution,
      onTick,
    )
  },
  unsubscribeBars: () => {
    socketIo.current?.close()
  },
})
