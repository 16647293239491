import ChartContainer from '../components/SymbolChart'

const SymbolChart = () => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <ChartContainer />
    </div>
  )
}

export default SymbolChart
