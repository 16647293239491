import axios from 'axios'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'
import _orderBy from 'lodash/orderBy'

import moment from 'moment'
import {
  Bar,
  LibrarySymbolInfo,
  ResolutionString,
  SubscribeBarsCallback,
} from '../../charting_library/charting_library'
import {
  calculateIntervalTime,
  convertedInterval,
  symbolChartRequest,
} from '../function'

export default ({ widgetRef, periodRef, socketIo }: any) => ({
  onReady: (callback: any) => {
    callback({ supports_marks: true })
  },
  searchSymbols: () => {},
  resolveSymbol: async (
    symbolName: any,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: any,
  ) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_DATA_PLATFORM_API_URL}/trading-view/symbols?symbol=${symbolName}`,
      )

      onSymbolResolvedCallback({
        ...data,
        supported_resolutions: ['60', '120', '180', '240', 'D', 'W', 'M'],
        intraday_multipliers: data.supported_resolutions,
        has_intraday: true,
      })
    } catch (error) {
      onResolveErrorCallback(error)
    }
  },
  getBars: async (
    symbolInfo: any,
    resolution: any,
    periodParams: any,
    onHistoryCallback: any,
    onErrorCallback: any,
  ) => {
    const { from, to } = calculateIntervalTime(
      periodParams.firstDataRequest,
      periodParams.from,
      periodParams.to,
      periodRef,
      widgetRef.current?.symbolInterval()?.interval,
    )

    const { bars, meta } = await symbolChartRequest(
      symbolInfo.ticker,
      from,
      to,
      widgetRef.current?.symbolInterval()?.interval,
      onErrorCallback,
    )

    onHistoryCallback(bars, meta)
  },

  subscribeBars: async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
  ) => {
    const convertedResolution = convertedInterval(
      widgetRef.current?.symbolInterval()?.interval,
    )

    socketIo.current?.close()

    socketIo.current = new WebSocket(
      `${process.env.REACT_APP_SOCKET_URL_V2}/last-tick/${symbolInfo.ticker}/${convertedResolution}`,
    )

    socketIo.current.onmessage = ({ data }: any) => {
      try {
        const response = JSON.parse(data)

        for (let i = 0; i < response.t.length; ++i) {
          const barValue: Bar = {
            time: moment().unix() * 1000,
            close: parseFloat(response.c[i]),
            open: parseFloat(response.o[i]),
            high: parseFloat(response.h[i]),
            low: parseFloat(response.l[i]),
            volume: parseFloat(response.v[i]),
          }

          onTick(barValue)
        }
      } catch (error) {}
    }
  },
  unsubscribeBars: () => {
    socketIo.current?.close()
  },
})
