import SectorRankChart from '../components/RatingChart/Sector'

const SectorRank = () => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <SectorRankChart />
    </div>
  )
}

export default SectorRank
