import { Ref, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import _uniqBy from 'lodash/uniqBy'
import {
  ChartingLibraryWidgetOptions,
  CustomIndicator,
  LanguageCode,
  PineJS,
  RawStudyMetaInfoId,
  widget,
} from '../../../charting_library'
import DATAFEED, { getBars } from './datafeed'
import { RANK_COLORS } from '../constant'
import {
  addCustomFullScreenButton,
  calculateIntervalTime,
  checkExternalFeatureParams,
  defaultChartWidgetOptions,
} from '../../function'
import { useLazyQuery, useSubscription } from '@apollo/client'
import {
  GET_STOCK_INFO,
  GET_STOCK_RATING,
} from '../../../graphQL/queries/rating'
import { SUB_STOCKS_RATING } from '../../../graphQL/subscription/rating'
import moment from 'moment'
import _isEqual from 'lodash/isEqual'
import _orderBy from 'lodash/orderBy'
import _last from 'lodash/last'
import _difference from 'lodash/difference'

const RatingChartContainer = () => {
  const widgetRef = useRef<any>()
  const ref: Ref<any> = useRef()
  const storeData = useRef<any[]>([])
  const dataFeedFunc = useRef<any>({})
  const [searchParams, _] = useSearchParams()
  const hasSendMessage = useRef<any>(false)
  const symbols = useRef(searchParams.get('symbols')?.split(',') || [])
  const locale = (searchParams.get('language') as LanguageCode) || 'vi'
  const type = searchParams.get('type') || 'POINT'
  const hasFilter = searchParams.get('hasFilter') || ''
  const canAccess = searchParams.get('canAccess') || ''
  const sector = searchParams.get('sector') || ''
  const theme = searchParams.get('theme') || ''

  const [skipSubscription, setSkipSubscription] = useState(true)
  const periodRef = useRef({
    to: moment().unix(),
    from: moment().subtract(6, 'months').unix(),
  })

  const [stockInfo, setStockInfo] = useState([])
  const [fetchData] = useLazyQuery(GET_STOCK_RATING)

  const [getData] = useLazyQuery(GET_STOCK_INFO, {
    onCompleted: (data) => {
      setStockInfo(data?.datxStocksCompanyByTicker)
    },
  })

  const onResetData = async () => {
    const { from, to } = calculateIntervalTime(true, 0, 0, periodRef, 'D')

    try {
      await getBars(fetchData, symbols.current, from, to, storeData, type, null)
    } catch (error) {}
  }

  useSubscription(SUB_STOCKS_RATING, {
    skip: skipSubscription,
    onData: async ({
      data: {
        data: { stockRatingV2: realtimeData },
      },
    }) => {
      let stockRatingV2 = _orderBy(
        sector
          ? realtimeData.filter((a: any) => a.sector === sector)
          : realtimeData,
        [type === 'POINT' ? 'totalPoints' : 'rank'],
        [type === 'POINT' ? 'desc' : 'asc'],
      )

      if (storeData.current.length > 0 && dataFeedFunc.current?.onTick) {
        if (!hasSendMessage.current)
          window.parent.postMessage(
            JSON.stringify({ site: 'DATX', message: 'STOCK_SOCKET' }),
            '*',
          )

        let hasChangeRange = false

        if (canAccess !== '' && hasFilter !== '') {
          const newData =
            canAccess === 'false' || hasFilter === 'false'
              ? stockRatingV2
                  .map((a: any) => a.code)
                  .slice(0, canAccess === 'false' ? 3 : 5)
              : stockRatingV2
                  .filter((a: any) => symbols.current.includes(a.code))
                  .map((a: any) => a.code)

          if (!_isEqual(newData, symbols.current)) {
            try {
              if (newData[0] === symbols.current[0]) {
                if (_difference(newData, symbols.current).length !== 0) {
                  symbols.current = newData
                  storeData.current = []
                  await onResetData()
                  dataFeedFunc.current?.onResetCacheNeededCallback()
                  widgetRef.current?.chart()?.resetData()
                }

                symbols.current = newData
                hasChangeRange = true
              } else {
                symbols.current = newData
                storeData.current = []
                widgetRef.current.chart().setSymbol(symbols.current[0])
                return
              }
            } catch (error) {
              console.log('Stock realtime error', error)
            }
          }
        }

        const firstRank = stockRatingV2.find(
          (a: any) => a.code === symbols.current[0],
        )

        if (firstRank) {
          const value =
            type === 'POINT' ? firstRank.totalPoints : firstRank.rank

          const lastItem = _last(_orderBy(storeData.current, 'time', 'asc'))
          const newSocketData: any = {
            time: lastItem
              ? lastItem?.time
              : moment().startOf('day').unix() * 1000,
            close: value,
            open: value,
            high: value,
            low: value,
            volume: value,
          }

          stockRatingV2.map((a: any) => {
            if (
              a.code !== symbols.current[0] &&
              symbols.current.includes(a.code)
            ) {
              newSocketData[a.code] = type === 'POINT' ? a.totalPoints : a.rank
            }
          })

          onRealtimeData(newSocketData)
          if (hasChangeRange) createStudy(widgetRef.current)
        }

        if (!hasSendMessage.current) hasSendMessage.current = true
      }
    },
  })

  const onRealtimeData = (data: any) => {
    const oldData = storeData.current.filter((a) => a.time !== data.time)
    storeData.current = [...oldData, data]
    dataFeedFunc.current?.onTick({
      ...data,
      time: moment().unix() * 1000,
    })
  }

  const customIndicatorsGetter = (PineJS: PineJS) => {
    const itemConfig = (code: string) => {
      return {
        name: `StockRank${code}`,
        metainfo: {
          _metainfoVersion: 51,
          id: `stock-${code}@tv-basicstudies-1` as RawStudyMetaInfoId,
          name: code,
          description: code,
          shortDescription: code,
          is_hidden_study: false,
          is_price_study: true,
          isCustomIndicator: true,
          format: {
            type: 'price',
            precision: 0,
          },
          plots: [{ id: 'plot_0', type: 'line' as any }],
          defaults: {
            styles: {
              plot_0: {
                linestyle: 0,
                visible: true,
                linewidth: 2,
                plottype: 2 as any,
                trackPrice: false,
              },
            },
            inputs: {},
          },
          styles: {
            plot_0: {
              title: code,
              histogramBase: 0,
            },
          },
          inputs: [],
        },
        constructor: function () {
          const mainThis = this as any
          mainThis.init = function (context: unknown, inputCallback: never) {
            this._context = context
            this._input = inputCallback
          }
          mainThis.main = function (context: unknown, inputCallback: never) {
            this._context = context
            this._input = inputCallback

            const time = PineJS.Std.time(this._context)

            const value = storeData.current.find(
              (a: any) => String(a.time) === String(time),
            ) as any

            return value && value[code] ? [value[code]] : []
          }
        },
      } as CustomIndicator
    }

    const defaultItem: CustomIndicator[] = symbols.current.map((code) =>
      itemConfig(code),
    )

    const items: CustomIndicator[] = stockInfo
      .filter((a: any) => !symbols.current.includes(a.ticker))
      .map(({ ticker }) => itemConfig(ticker))

    return Promise.resolve<CustomIndicator[]>([...defaultItem, ...items])
  }

  const createStudy = (tvWidget: any) => {
    try {
      tvWidget.chart().removeAllStudies()

      symbols.current.slice(1, 10).map((code, index) => {
        try {
          tvWidget.chart().createStudy(
            code,
            false,
            false,
            {},
            {
              styles: {
                plot_0: {
                  linestyle: 0,
                  visible: true,
                  linewidth: 2,
                  plottype: 2 as any,
                  trackPrice: false,
                  color: RANK_COLORS[index + 1],
                },
              },
            },
          )
        } catch (error) {
          console.log('stock study not loaded', code)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!ref.current) return
    if (stockInfo.length === 0) return

    const widgetOptions: ChartingLibraryWidgetOptions = {
      ...defaultChartWidgetOptions,
      theme: theme === 'light' ? 'Light' : 'Dark',
      symbol: symbols.current[0],
      datafeed: DATAFEED({
        dataFeedFunc,
        storeData,
        fetchData,
        symbols,
        type,
        widgetRef,
        periodRef,
        setSkipSubscription,
      }),
      container: ref.current,
      locale,
      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
        'symbol_search_hot_key',
        'header_chart_type',
        'header_resolutions',
      ],
      enabled_features: [
        'study_templates',
        'pinch_scale',
        'show_zoom_and_move_buttons_on_touch',
        'vert_touch_drag_scroll',
        'horz_touch_drag_scroll',
      ],
      timeframe: '3m',
      custom_indicators_getter: customIndicatorsGetter,
      time_frames: [
        {
          text: '2y',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '2 Years',
          title: '2Y',
        },
        {
          text: '1y',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '1 Years',
          title: '1Y',
        },
        {
          text: '6m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '6 Months',
          title: '6M',
        },
        {
          text: '3m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '3 Months',
          title: '3M',
        },
        {
          text: '1m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '1 Months',
          title: '1M',
        },
      ],
    }

    const tvWidget = new widget(
      checkExternalFeatureParams(searchParams, widgetOptions),
    )

    tvWidget.headerReady().then(() => {
      if (searchParams.get('custom-fullscreen'))
        addCustomFullScreenButton(tvWidget, 'FULL_SCREEN_MODE_SECTOR')
    })

    tvWidget.onChartReady(() => {
      widgetRef.current = tvWidget
      tvWidget.chart().setChartType(2)
      tvWidget.chart().executeActionById('lineHide')
      tvWidget.chart().getSeries().setChartStyleProperties(2, {
        color: RANK_COLORS[0],
      })

      createStudy(tvWidget)

      tvWidget
        .chart()
        .onSymbolChanged()
        .subscribe(null, () => {
          createStudy(tvWidget)
        })
    })

    widgetRef.current = tvWidget

    return () => {
      if (tvWidget) tvWidget.remove()
    }
  }, [searchParams, stockInfo])

  useEffect(() => {
    getData()
  }, [])

  return (
    <div ref={ref} className={'TVChartContainer'} style={{ height: '100%' }} />
  )
}

export default RatingChartContainer
