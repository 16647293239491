import { Ref, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import _sortBy from 'lodash/sortBy'
import _uniqBy from 'lodash/uniqBy'
import PropTypes from 'prop-types'

import {
  ChartingLibraryWidgetOptions,
  CustomIndicator,
  LanguageCode,
  RawStudyMetaInfoId,
  widget,
} from '../../charting_library'
import DATAFEED from './datafeed'
import {
  addCustomFullScreenButton,
  checkExternalFeatureParams,
  defaultChartWidgetOptions,
} from '../function'

const MAColor: any = {
  '3': '#68B984',
  '6': '#CE7777',
  '12': '#FF7000',
  '24': '#FFBF00',
  '48': '#C147E9',
}

const ChartContainer = () => {
  const widgetRef = useRef<any>()
  const ref: Ref<any> = useRef()
  const data = useRef([])
  const [searchParams, _] = useSearchParams()

  const customIndicatorsGetter = (PineJS: any) => {
    const items = Object.keys(MAColor).map(
      (name) =>
        ({
          name: `MA${name}`,
          metainfo: {
            _metainfoVersion: 51,
            id: `MA${name}@tv-basicstudies-1` as RawStudyMetaInfoId,
            name: `MA${name}`,
            description: `MA${name}`,
            shortDescription: `MA${name}`,
            is_hidden_study: false,
            is_price_study: true,
            isCustomIndicator: true,
            format: {
              type: 'price',
              precision: 1,
            },
            plots: [{ id: 'plot_0', type: 'line' as any }],
            defaults: {
              styles: {
                plot_0: {
                  linestyle: 0,
                  visible: true,
                  linewidth: 2,
                  plottype: 2 as any,
                  trackPrice: false,
                  color: MAColor[name],
                },
              },
              inputs: {},
            },
            styles: {
              plot_0: {
                title: `MA${name}`,
                histogramBase: 0,
              },
            },
            inputs: [],
          },
          constructor: function () {
            const mainThis = this as any
            mainThis.init = function (context: unknown, inputCallback: never) {
              this._context = context
              this._input = inputCallback
            }
            mainThis.main = function (context: unknown, inputCallback: never) {
              this._context = context
              this._input = inputCallback
              const time = PineJS.Std.time(this._context)
              const value = _uniqBy(data.current, 'timestamp').find(
                (a: any) => String(a.time) === String(time),
              ) as any

              return [value && value[`MA${name}`] ? value[`MA${name}`] : 0]
            }
          },
        } as CustomIndicator),
    )

    return Promise.resolve<CustomIndicator[]>(items)
  }

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      ...defaultChartWidgetOptions,
      symbol: searchParams.get('symbol') as string,
      datafeed: DATAFEED({
        storeData: data,
        language: (searchParams.get('language') as LanguageCode) || 'vi',
        widgetRef,
      }),
      container: ref.current,
      locale: (searchParams.get('language') as LanguageCode) || 'vi',
      disabled_features: ['use_localstorage_for_settings'],
      enabled_features: ['study_templates'],
      timeframe: '6M',
      custom_indicators_getter: customIndicatorsGetter,
    }

    const tvWidget = new widget(
      checkExternalFeatureParams(searchParams, widgetOptions),
    )

    tvWidget.headerReady().then(() => {
      if (searchParams.get('custom-fullscreen'))
        addCustomFullScreenButton(tvWidget)
    })

    tvWidget.onChartReady(() => {
      widgetRef.current = tvWidget
      tvWidget.chart().setChartType(2)
      tvWidget.chart().removeAllStudies()

      Object.keys(MAColor).map((key) => {
        tvWidget.chart().createStudy(`MA${key}`, false, false, {})
      })
    })

    return () => {
      if (tvWidget) tvWidget.remove()
    }
  }, [searchParams])

  return (
    <div ref={ref} className="TVChartContainer" style={{ height: '100%' }} />
  )
}
ChartContainer.propTypes = {
  datafeedUrl: PropTypes.string,
}
export default ChartContainer
