import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'
import _map from 'lodash/map'
import moment from 'moment'
import { sectorTranslationEn, sectorTranslationVi } from '../constant'
import {
  LibrarySymbolInfo,
  ResolutionString,
  SubscribeBarsCallback,
} from '../../../charting_library'
import { calculateIntervalTime } from '../../function'

export const getBars = async (
  fetchData: any,
  codes: string[],
  from: number,
  to: number,
  storeData: any,
  type: string,
  onHistoryCallback: any,
) => {
  const {
    data: { sectorRatingHistory },
  } = await fetchData({
    variables: {
      codes: codes,
      startDate: moment.unix(from).format('YYYY-MM-DD'),
      endDate: moment.unix(to).format('YYYY-MM-DD'),
    },
  })

  const convertedResponse: any = {}

  sectorRatingHistory
    .slice(1, 10)
    .map(
      (a: any) =>
        (convertedResponse[a.code] = _groupBy(a.data, (item: any) =>
          moment(item.date).startOf('days').format('DD-MM-YYYY'),
        )),
    )

  const tickerData =
    sectorRatingHistory.find((a: any) => a.code === codes[0])?.data || []

  const convertedData = _orderBy(tickerData, ['date', 'desc'])
    .sort()
    .map((item: any, index: number) => {
      const ranks: Record<string, any> = {}
      codes.slice(1, 10).map((sector: string) => {
        if (convertedResponse[sector]) {
          const data =
            convertedResponse[sector][
              moment(item.date).startOf('days').format('DD-MM-YYYY')
            ] || []
          ranks[sector] =
            type === 'POINT' ? data[0]?.totalPoints : data[0]?.rank
        }
      })

      const value = type === 'POINT' ? item.totalPoints : item.rank

      return {
        low: value,
        high: value,
        open: value,
        close: value,
        volume: value,
        time: moment(item.date).unix() * 1000,
        opening_time: moment(item.date).unix() * 1000,
        closing_time: tickerData[index + 1]
          ? moment(tickerData[index + 1].date).unix() * 1000
          : moment(item.date).unix() * 1000,
        ...ranks,
      }
    })

  storeData.current = [...storeData.current, ...convertedData]

  if (onHistoryCallback)
    onHistoryCallback(convertedData, {
      noData: sectorRatingHistory.length === 0,
    })
}

export default ({
  dataFeedFunc,
  fetchData,
  language,
  symbols,
  type,
  storeData,
  widgetRef,
  periodRef,
  setSkipSubscription,
}: any) => ({
  onReady: (callback: any) => {
    callback({})
  },
  searchSymbols: () => {},
  resolveSymbol: async (
    symbolName: any,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: any,
  ) => {
    try {
      const data = {
        name:
          language === 'vi'
            ? sectorTranslationVi[symbols.current[0]]
            : sectorTranslationEn[symbols.current[0]],
        minmov: 2,
        minmov2: 0,
        pointvalue: 2,
        session: '24x7',
        has_intraday: true,
        has_no_volume: false,
        supported_resolutions: ['D', '1W', '5W', '1M', '3M', '6M', '1Y', '2Y'],
        pricescale: 2,
        ticker: symbolName,
        volume_precision: 4,
      }

      onSymbolResolvedCallback(data)
    } catch (error) {
      onResolveErrorCallback(error)
    }
  },
  getBars: async (
    symbolInfo: any,
    resolution: any,
    periodParams: any,
    onHistoryCallback: any,
    onErrorCallback: any,
  ) => {
    if (!dataFeedFunc.current?.onHistoryCallback)
      dataFeedFunc.current['onHistoryCallback'] = onHistoryCallback

    const { from, to } = calculateIntervalTime(
      periodParams.firstDataRequest,
      periodParams.from,
      periodParams.to,
      periodRef,
      widgetRef.current?.symbolInterval()?.interval,
    )

    try {
      await getBars(
        fetchData,
        symbols.current,
        from,
        to,
        storeData,
        type,
        onHistoryCallback,
      )

      if (setSkipSubscription && periodParams.firstDataRequest) {
        setTimeout(() => {
          setSkipSubscription(false)
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      onErrorCallback(error)
    }
  },
  subscribeBars: async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void,
  ) => {
    if (!dataFeedFunc.current?.onTick) dataFeedFunc.current['onTick'] = onTick
    if (!dataFeedFunc.current?.onResetCacheNeededCallback)
      dataFeedFunc.current['onResetCacheNeededCallback'] =
        onResetCacheNeededCallback
  },
  unsubscribeBars: () => {},
})
