import axios from 'axios'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'
import _orderBy from 'lodash/orderBy'

import moment from 'moment'
import {
  Bar,
  LibrarySymbolInfo,
  ResolutionString,
  SubscribeBarsCallback,
} from '../../charting_library/charting_library'
import {
  calculateIntervalTime,
  convertedInterval,
  symbolChartRequest,
} from '../function'

export type Recommendation = {
  id: string
  op: number
  s: string
  cp: number
  od: string
  cd: string
  date: string
  type: string
  recTypeLabel: string
}

const headerStyle = {
  color: '#B0B0B0',
  fontSize: 12,
  fontWeight: 500,
}

const itemStyled = { color: '#FFFFFF', fontSize: 12, fontWeight: 500 }

const columns = [
  {
    renderHeader: (width: number) => (
      <div style={{ ...headerStyle, width: `${width}%` }}>Ngày GD</div>
    ),
    render: (item: Recommendation, width: number) => (
      <div style={{ ...itemStyled, width: `${width}%` }}>
        {moment(item.date).format('DD/MM/YY')}
      </div>
    ),
  },
  {
    renderHeader: (width: number) => (
      <div style={{ ...headerStyle, width: `${width}%` }}>Tín hiệu</div>
    ),
    render: (item: Recommendation, width: number) => (
      <div
        style={{
          ...itemStyled,
          width: `${width}%`,
          color: item.type === 'SELL' ? '#F24B78' : '#1ED994',
          textAlign: 'center',
        }}
      >
        {item.type === 'SELL' ? 'Bán' : 'Mua'}
      </div>
    ),
  },
  {
    renderHeader: (width: number) => (
      <div style={{ ...headerStyle, width: `${width}%` }}>Giá</div>
    ),
    render: (item: Recommendation, width: number) => (
      <div style={{ ...itemStyled, width: `${width}%` }}>
        {item.type === 'SELL' ? item.cp : item.op}
      </div>
    ),
  },
]

const buyColumn = {
  renderHeader: (width: number) => (
    <div style={{ ...headerStyle, width: `${width}%` }}>Ngày mua</div>
  ),
  render: (item: Recommendation, width: number) => (
    <div style={{ ...itemStyled, width: `${width}%` }}>
      {item.type === 'SELL' ? moment(item.od).format('DD/MM/YY') : ''}
    </div>
  ),
}

const signalColumn = {
  renderHeader: (width: number) => (
    <div style={{ ...headerStyle, width: `${width}%` }}>Loại tín hiệu</div>
  ),
  render: (item: Recommendation, width: number) => (
    <div style={{ ...itemStyled, width: `${width}%` }}>{item.recTypeLabel}</div>
  ),
}

const RecommendationTable = ({
  data,
  isBuy,
}: {
  data: Recommendation[]
  isBuy?: boolean
}) => {
  const percentageMap = isBuy ? [30, 23, 22, 25] : [20, 16, 16, 20, 23]
  const tableColumns = isBuy
    ? [...columns, signalColumn]
    : [...columns, buyColumn, signalColumn]

  return (
    <div
      style={{
        background: '#343957',
        position: 'absolute',
        right: -20,
        top: -20,
        width: isBuy ? 360 : 400,
        padding: 5,
        paddingTop: 0,
        zIndex: 999,
        borderRadius: 4,
      }}
    >
      <div style={{ display: 'flex', padding: 10, paddingBottom: 5 }}>
        {tableColumns.map((item, index) =>
          item.renderHeader(percentageMap[index]),
        )}
      </div>
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        {data.map((item) => (
          <div
            style={{ display: 'flex', padding: '5px 10px' }}
            key={`${item.id}-${item.type}-${item.date}`}
          >
            {tableColumns.map((column, index) =>
              column.render(item, percentageMap[index]),
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

const groupCondition = (resolution: string, time: string) => {
  switch (resolution) {
    case '1W':
      return `${moment(time).format('MM-YYYY')}-${moment(time).week()}`
    case '1M':
      return moment(time).startOf('months').format('MM-YYYY')

    case '60':
      return moment(time) > moment(time).startOf('days').add(15, 'hours')
        ? moment(time).startOf('days').add(14, 'hours').format('DD-MM-YYYY HH')
        : moment(time).format('DD-MM-YYYY HH')
    case '120':
      // has 3 candle
      // case 1 candle from 9h => 11h

      if (moment(time) < moment(time).startOf('days').add(11, 'hours'))
        return moment(time)
          .startOf('days')
          .add(9, 'hours')
          .format('DD-MM-YYYY HH')

      // case 2 candle from 11h => 13h
      if (
        moment(time) >= moment(time).startOf('days').add(11, 'hours') &&
        moment(time) < moment(time).startOf('days').add(13, 'hours')
      )
        return moment(time)
          .startOf('days')
          .add(11, 'hours')
          .format('DD-MM-YYYY HH')

      // case 3 candle from 13h to end candle
      return moment(time)
        .startOf('days')
        .add(13, 'hours')
        .format('DD-MM-YYYY HH')

    case '180':
      // has 2 candle
      // case 1 candle from 9h => 11h
      if (moment(time) < moment(time).startOf('days').add(12, 'hours'))
        return moment(time)
          .startOf('days')
          .add(9, 'hours')
          .format('DD-MM-YYYY HH')

      // case 2 candle from 12h to end candle
      return moment(time)
        .startOf('days')
        .add(12, 'hours')
        .format('DD-MM-YYYY HH')

    case '240':
      // has 2 candle
      // case 1 candle from 9h => 13h
      if (moment(time) < moment(time).startOf('days').add(13, 'hours'))
        return moment(time)
          .startOf('days')
          .add(9, 'hours')
          .format('DD-MM-YYYY HH')

      // case 2 candle from 13h to end candle
      return moment(time)
        .startOf('days')
        .add(13, 'hours')
        .format('DD-MM-YYYY HH')

    default:
      return moment(time).endOf('days').format('DD-MM-YYYY')
  }
}

const calculatedMark = (data: Recommendation[], resolution: string) => {
  const marks: any = []
  const items: any = {}

  const response = (data || []).filter(
    (a: Recommendation) => moment(a.od).unix() <= moment().endOf('days').unix(),
  )

  if (response?.length > 0) {
    const buyRecommendations = _groupBy(response, (item) =>
      groupCondition(resolution, item.od),
    )

    const sellRecommendations = _groupBy(
      response.filter(
        (a: Recommendation) =>
          a.cd && moment(a.cd).unix() <= moment().endOf('days').unix(),
      ),
      (item: Recommendation) => groupCondition(resolution, item.cd),
    )

    _map(buyRecommendations, (item, key) => {
      items[key] = item.map((a) => ({ ...a, type: 'BUY', date: a.od }))
    })

    _map(sellRecommendations, (item, key) => {
      if (items[key]) {
        const newData = [
          ...items[key],
          ...item.map((a) => ({ ...a, type: 'SELL', date: a.cd })),
        ]
        items[key] = _orderBy(newData, 'date', 'desc')
      } else {
        items[key] = item.map((a) => ({
          ...a,
          type: 'SELL',
          date: a.cd,
        }))
      }
    })

    _map(items, (recommendation, date) => {
      const isBuy =
        recommendation?.length ===
        recommendation.filter((a: Recommendation) => a.type === 'BUY').length

      const isSell =
        recommendation?.length ===
        recommendation.filter((a: Recommendation) => a.type === 'SELL').length

      if (recommendation?.length > 1) {
        marks.push({
          id: `recommendation-${date}-${recommendation[0]?.id}`,
          time:
            resolution === '60' ||
            resolution === '120' ||
            resolution === '180' ||
            resolution === '240'
              ? moment(date, 'DD-MM-YYYY HH').startOf('hours').unix()
              : moment(recommendation[0].date).endOf('days').unix(),
          color: {
            border: 'white',
            background:
              isSell || isBuy ? (isBuy ? '#00BA3C' : '#EA1212') : '#005DFB',
            borderWidth: 0,
          },
          borderWidth: 2,
          hoveredBorderWidth: 2,
          text: <RecommendationTable data={recommendation} isBuy={isBuy} />,
          label: ' ',
          labelFontColor: 'white',
          minSize: 14,
        })
      } else {
        marks.push({
          id: `recommendation-${date}-${recommendation[0]?.id}`,
          time:
            resolution === '60' ||
            resolution === '120' ||
            resolution === '180' ||
            resolution === '240'
              ? moment(date, 'DD-MM-YYYY HH').startOf('hours').unix()
              : moment(recommendation[0].date).endOf('days').unix(),
          color: {
            border: 'white',
            background: isBuy ? '#00BA3C' : '#EA1212',
            borderWidth: 0,
          },
          borderWidth: 2,
          hoveredBorderWidth: 2,
          text: <RecommendationTable data={recommendation} isBuy={isBuy} />,
          label: ' ',
          labelFontColor: 'white',
          minSize: 14,
        })
      }
    })
  }

  return marks
}

export default ({
  widgetRef,
  periodRef,
  socketIo,
  getRecommendation,
}: any) => ({
  onReady: (callback: any) => {
    callback({ supports_marks: true })
  },
  searchSymbols: () => {},
  resolveSymbol: async (
    symbolName: any,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: any,
  ) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_DATA_PLATFORM_API_URL}/trading-view/symbols?symbol=${symbolName}`,
      )

      onSymbolResolvedCallback({
        ...data,
        supported_resolutions: ['60', '120', '180', '240', 'D', 'W', 'M'],
        intraday_multipliers: data.supported_resolutions,
        has_intraday: true,
      })
    } catch (error) {
      onResolveErrorCallback(error)
    }
  },
  getBars: async (
    symbolInfo: any,
    resolution: any,
    periodParams: any,
    onHistoryCallback: any,
    onErrorCallback: any,
  ) => {
    const { from, to } = calculateIntervalTime(
      periodParams.firstDataRequest,
      periodParams.from,
      periodParams.to,
      periodRef,
      widgetRef.current?.symbolInterval()?.interval,
    )

    const { bars, meta } = await symbolChartRequest(
      symbolInfo.ticker,
      from,
      to,
      widgetRef.current?.symbolInterval()?.interval,
      onErrorCallback,
    )

    onHistoryCallback(bars, meta)
  },
  async getMarks(
    symbolInfo: any,
    from: any,
    to: any,
    onDataCallback: any,
    resolution: any,
  ) {
    const {
      data: { stockSignalHistory },
    } = await getRecommendation({
      variables: {
        input: {
          symbols: symbolInfo.symbol,
          startDate: moment.unix(from).format('YYYY-MM-DD'),
          endDate:
            to > moment().unix()
              ? moment().format('YYYY-MM-DD')
              : moment.unix(to).format('YYYY-MM-DD'),
          page: 1,
          limit: 9999,
        },
      },
    })

    const convertedData = (stockSignalHistory?.data || [])?.map(
      (item: any) => ({
        id: `${item.id}-${item.date}-${item.openDate}-${item.ticker}-${item.recType}`,
        s: item.ticker,
        od: item.openDate ? moment(item.openDate).toLocaleString() : null,
        op: item.openPrice,
        cd: item.closedDate ? moment(item.closedDate).toLocaleString() : null,
        cp: item.closedPrice,
        recTypeLabel: item.recTypeLabel,
      }),
    )

    const marks = calculatedMark(convertedData || [], resolution)
    onDataCallback(marks)
  },
  subscribeBars: async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
  ) => {
    const convertedResolution = convertedInterval(
      widgetRef.current?.symbolInterval()?.interval,
    )

    socketIo.current?.close()

    socketIo.current = new WebSocket(
      `${process.env.REACT_APP_SOCKET_URL_V2}/last-tick/${symbolInfo.ticker}/${convertedResolution}`,
    )

    socketIo.current.onmessage = ({ data }: any) => {
      try {
        const response = JSON.parse(data)

        for (let i = 0; i < response.t.length; ++i) {
          const barValue: Bar = {
            time: moment().unix() * 1000,
            close: parseFloat(response.c[i]),
            open: parseFloat(response.o[i]),
            high: parseFloat(response.h[i]),
            low: parseFloat(response.l[i]),
            volume: parseFloat(response.v[i]),
          }

          onTick(barValue)
        }
      } catch (error) {}
    }
  },
  unsubscribeBars: () => {
    socketIo.current?.close()
  },
})
