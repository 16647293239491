import ChartContainer from '../components/DerivativeRecommendation'

const DerivativeRecommendationChart = () => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <ChartContainer />
    </div>
  )
}

export default DerivativeRecommendationChart
